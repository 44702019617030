import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RealTimeOccupancySpaceStatusIndicator from "../RealTimeOccupancySpaceStatusIndicator";
import Typography from "@mui/material/Typography";
import SyncInProgressTooltip from "../SyncInProgressTooltip";
import { serializeOccupant } from "common/format/occupant";
import { CurbSpace, Occupant, OnlineZone } from "common/graphql/queries";
import RealTimeOccupancySideBarSpaceOccupant from "../RealTimeOccupancySideBarSpaceOccupant";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import type { Theme } from "@mui/material/styles";

/**
 * Props interface for the RealTimeOccupancySideBarRealTimeTab component header.
 * @property {boolean} isSyncInProgress - Indicates whether synchronization is in progress.
 * @property {number | null} spaceIndex - The index of the selected space. Null if not applicable.
 * @property {OnlineZone} zone - The online zone data containing information about spaces and detected vehicles.
 * @property {() => void} [onClickBack] - Optional callback function invoked when back button is clicked.
 * @property {"space" | "zone"} displayMode - The display mode of the component, either "space" or "zone".
 */
export interface RealTimeOccupancySideBarRealTimeTabProps {
  isSyncInProgress: boolean;
  selection: Selection;
  onChangeSelection: (selection: Selection) => void;
  onClickBack?: () => void;
  displayMode: "space" | "zone";
}

interface Selection {
  zone: OnlineZone;
  spaceIndex: number | null;
  occupantId: string | null;
}

/**
 * Component for the real-time tab in the sidebar of real-time occupancy.
 * Handles display of information related to spaces and zones.
 */
export const RealTimeOccupancySideBarRealTimeTab: React.FC<RealTimeOccupancySideBarRealTimeTabProps> = ({
  isSyncInProgress = false,
  selection,
  displayMode,
  onChangeSelection,
}) => {
  const zone = selection.zone;
  const spaceIndex = selection.spaceIndex;

  // State to manage visibility of the double parked violations menu
  const [isShowDoubleParkedVolitionsMenu, setIsShowDoubleParkedVolitionsMenu] = useState(false);
  const isZoneAvailableForUse = displayMode === "space" ? !zone.spaces?.length : !zone.occupants.length;
  // Fetch the selected space based on the index
  const selectedSpace = zone.spaces?.find((space) => space.index == spaceIndex);

  // Check if the selected space is occluded
  const isOccluded = selectedSpace?.isOccluded;
  // Extract double parked occupants
  const doubleParkedVolitions: Occupant[] =
    zone?.occupants.filter((occupant) => occupant?.status === "doubleparked") || [];

  useEffect(() => {
    setIsShowDoubleParkedVolitionsMenu(false);
  }, [selectedSpace]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "445px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "8px 0",
        boxSizing: "border-box",
      }}
    >
      {isZoneAvailableForUse && (
        <Box
          sx={{
            width: "100%",
            padding: (theme: Theme) => theme.spacing(2),
          }}
        >
          <Typography>Zone is available for use</Typography>
        </Box>
      )}
      {displayMode === "space" && !isZoneAvailableForUse && doubleParkedVolitions?.length > 0 && (
        <DoubleParkVolitionsMenu
          isSyncInProgress={isSyncInProgress}
          setIsShowDoubleParkedVolitionsMenu={setIsShowDoubleParkedVolitionsMenu}
          isShowDoubleParkedVolitionsMenu={isShowDoubleParkedVolitionsMenu}
          doubleParkOccupants={doubleParkedVolitions}
        />
      )}

      {!isZoneAvailableForUse && !isShowDoubleParkedVolitionsMenu && displayMode === "zone" && (
        <ZoneMenu isSyncInProgress={isSyncInProgress} selection={selection} onChangeSelection={onChangeSelection} />
      )}

      {!isZoneAvailableForUse && !isShowDoubleParkedVolitionsMenu && displayMode === "space" && !!selectedSpace && (
        <SpaceMenu selectedSpace={selectedSpace} isSyncInProgress={isSyncInProgress} isOccluded={!!isOccluded} />
      )}
    </Box>
  );
};

export default RealTimeOccupancySideBarRealTimeTab;

/**
 * Props interface for the DoubleParkVolitionsMenu component.
 * @property {boolean} isSyncInProgress - Indicates whether synchronization is in progress.
 * @property {Occupant[]} doubleParkOccupants - Array of occupants with double parking violations.
 * @property {boolean} isShowDoubleParkedVolitionsMenu - Indicates whether the double park volitions menu is displayed.
 * @property {(show: boolean) => void} setIsShowDoubleParkedVolitionsMenu - Callback function to toggle the display of double park volitions menu.
 */
export interface DoubleParkVolitionsMenuProps {
  isSyncInProgress: boolean;
  doubleParkOccupants: Occupant[];
  isShowDoubleParkedVolitionsMenu: boolean;
  setIsShowDoubleParkedVolitionsMenu: (show: boolean) => void;
}

/**
 * Component for displaying the menu of double parked volitions.
 */
export const DoubleParkVolitionsMenu = ({
  isSyncInProgress,
  setIsShowDoubleParkedVolitionsMenu,
  isShowDoubleParkedVolitionsMenu,
  doubleParkOccupants,
}: DoubleParkVolitionsMenuProps): JSX.Element => {
  // State to manage the selected occupant
  const [selectedOccupant, setSelectedOccupant] = useState<Occupant | null>(null);

  // Handle click on back button
  const handleBackClick = () => {
    setSelectedOccupant(null);
    setIsShowDoubleParkedVolitionsMenu(false);
  };

  return (
    <>
      {!isShowDoubleParkedVolitionsMenu && (
        <Alert
          sx={{
            width: "90%",
            margin: "0 16px",
            boxSizing: "border-box",
          }}
          severity="error"
          iconMapping={{
            error: <CircleOutlinedIcon color="error" sx={{ fontSize: "22px" }} />,
          }}
          action={
            <IconButton onClick={() => setIsShowDoubleParkedVolitionsMenu(true)}>
              <ArrowForwardIosIcon color="error" sx={{ fontSize: "16px", cursor: "pointer" }} />
            </IconButton>
          }
        >
          <Typography
            variant="subtitle2"
            color="error"
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "12px",
            }}
          >
            Double Parks Detected
          </Typography>
        </Alert>
      )}

      {isShowDoubleParkedVolitionsMenu && (
        <>
          {!selectedOccupant && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
                padding: "13px 16px",
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontWeight={500}
                sx={{
                  textTransform: "capitalize",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "13px",
                }}
              >
                <ArrowBackIos sx={{ fontSize: "16px", cursor: "pointer" }} onClick={handleBackClick} />
                Double Parks (in the zone)
              </Typography>
              {isSyncInProgress && <SyncInProgressTooltip toolTipPosition="left" />}
            </Box>
          )}
          {!selectedOccupant ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "auto",
              }}
            >
              {doubleParkOccupants?.map((occupant, index) => (
                <OccupantListItem key={index} occupant={occupant} onSelect={setSelectedOccupant} />
              ))}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                  padding: "13px 16px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  fontWeight={500}
                  sx={{
                    textTransform: "capitalize",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "13px",
                  }}
                >
                  <ArrowBackIos
                    sx={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => setSelectedOccupant(null)}
                  />
                  <RealTimeOccupancySpaceStatusIndicator status={selectedOccupant?.status} />
                  {serializeOccupant(selectedOccupant)}
                </Typography>
                {isSyncInProgress && <SyncInProgressTooltip toolTipPosition="left" />}
              </Box>
              <RealTimeOccupancySideBarSpaceOccupant occupant={selectedOccupant} />
            </>
          )}
        </>
      )}
    </>
  );
};

/**
 * Props interface for the ZoneMenu component.
 * @property {boolean} isSyncInProgress - Indicates whether synchronization is in progress.
 * @property {OnlineZone} zoneData - The online zone data containing information about spaces and detected vehicles.
 */
export interface ZoneMenuProps {
  isSyncInProgress: boolean;
  selection: Selection;
  onChangeSelection: (selection: Selection) => void;
}

/**
 * Component for displaying the menu of detected vehicles in a zone.
 */
export const ZoneMenu = ({ isSyncInProgress, selection, onChangeSelection }: ZoneMenuProps): JSX.Element => {
  const selectedOccupant = selection.occupantId
    ? selection.zone.occupants.find((occ) => occ.vehicle.id === selection.occupantId) || null
    : null;
  const occupants = selection.zone.occupants;

  return (
    <>
      {!selectedOccupant && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "8px",
            padding: "13px 16px",
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            Detected Vehicles
          </Typography>
          {isSyncInProgress && <SyncInProgressTooltip />}
        </Box>
      )}
      {!selectedOccupant && occupants?.length > 0 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {occupants?.map((occupant) => (
            <OccupantListItem
              key={occupant.vehicle.id}
              occupant={occupant}
              onSelect={(occ) =>
                onChangeSelection({ zone: selection.zone, spaceIndex: null, occupantId: occ.vehicle.id })
              }
            />
          ))}
        </Box>
      )}
      {!!selectedOccupant && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
            padding: "13px 16px",
          }}
        >
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "13px",
            }}
          >
            <ArrowBackIos
              sx={{ fontSize: "16px", cursor: "pointer" }}
              onClick={() => onChangeSelection({ zone: selection.zone, spaceIndex: null, occupantId: null })}
            />
            {selectedOccupant.status !== "available" && (
              <RealTimeOccupancySpaceStatusIndicator status={selectedOccupant.status} />
            )}
            {serializeOccupant(selectedOccupant)}
          </Typography>
          {isSyncInProgress && <SyncInProgressTooltip toolTipPosition="left" />}
        </Box>
      )}
      {selectedOccupant && <RealTimeOccupancySideBarSpaceOccupant occupant={selectedOccupant} />}
    </>
  );
};

/**
 * Props interface for the SpaceMenu component.
 * @property {boolean} isSyncInProgress - Indicates whether synchronization is in progress.
 * @property {boolean} isOccluded - Indicates whether the space is occluded.
 * @property {CurbSpace} selectedSpace - The selected curb space.
 */
export interface SpaceMenuProps {
  isSyncInProgress: boolean;
  isOccluded: boolean;
  selectedSpace: CurbSpace;
}

/**
 * Component for displaying the menu of a selected space.
 */
export const SpaceMenu = ({ isSyncInProgress, selectedSpace, isOccluded }: SpaceMenuProps): JSX.Element => {
  if (isOccluded) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "12px",
          padding: "8px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography>No vehicle detected</Typography>

          {isSyncInProgress && <SyncInProgressTooltip />}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "8px",
            padding: "12px 16px",
            backgroundColor: "rgba(0, 0, 0, 0.09)",
          }}
        >
          <InfoOutlinedIcon
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          />
          <Typography>Unable to detect vehicles due to potential occlusion.</Typography>
        </Box>
      </Box>
    );
  }

  if (!selectedSpace.occupant) {
    return (
      <Box
        sx={{
          width: "100%",
          padding: (theme: Theme) => theme.spacing(2),
        }}
      >
        <Typography>Space is available for use</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          padding: "13px 16px",
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight={500}
          sx={{
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "13px",
          }}
        >
          {selectedSpace.occupant.status && (
            <RealTimeOccupancySpaceStatusIndicator status={selectedSpace.occupant.status} />
          )}
          {serializeOccupant(selectedSpace.occupant)}
        </Typography>
        {isSyncInProgress && <SyncInProgressTooltip toolTipPosition="left" />}
      </Box>
      <RealTimeOccupancySideBarSpaceOccupant occupant={selectedSpace.occupant} />
    </>
  );
};

/**
 * Props interface for the OccupantListItem component.
 * @property {Occupant} occupant - The occupant object to display in the list item.
 * @property {(occupant: Occupant) => void} onSelect - Callback function invoked when the occupant item is selected.
 */
export interface OccupantListItemProps {
  occupant: Occupant;
  onSelect: (occupant: Occupant) => void;
}

/**
 * Component for displaying an item in the list of occupants.
 */
export const OccupantListItem = ({ occupant, onSelect }: OccupantListItemProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        padding: "8px 16px",
        cursor: "pointer",
      }}
      onClick={() => onSelect(occupant)}
    >
      <Box width={"100%"} display={"flex"} gap={1.25} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <RealTimeOccupancySpaceStatusIndicator status={occupant?.status} />
          <Typography variant="subtitle2" color="text.secondary" fontWeight={500} sx={{ textTransform: "capitalize" }}>
            {serializeOccupant(occupant)}
          </Typography>
        </Box>
      </Box>
      <ArrowForwardIosIcon sx={{ fontSize: "16px", mr: 1.5 }} />
    </Box>
  );
};
