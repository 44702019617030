import { useQuery } from "@apollo/client";
import { formatCurrency } from "common/format";
import {
  EnforcementIntentsOrderBy,
  GET_ENFORCEMENT_INTENTS_SUMMARY,
  GetEnforcementIntentsSummaryData,
  GetEnforcementIntentsSummaryVars,
  GetEnforcementIntentsWhereFilter,
} from "common/graphql";
import { EnforcementIntentSummary } from "common/graphql/fragments/enforcementIntentSummary";
import { parseSortModel, serializeSortModel } from "common/graphql/queryString";
import Paper from "@mui/material/Paper";
import { DataGridPro, GridActionsCellItem, GridActionsColDef, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import type { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const NoticeSummaryTable: React.FC<NoticeSummaryTableProps> = ({ variant }) => {
  const navigate = useNavigate();

  const TABLE_COLUMNS = React.useMemo(
    () => [
      {
        field: "noticeNumber",
        headerName: "Notice Number",
        align: "left",
        headerAlign: "left",
        sortable: false,
        width: 200,
      } as GridColDef<EnforcementIntentSummary, string>,
      {
        field: "noticeType",
        headerName: "Notice Type",
        align: "left",
        headerAlign: "left",
        sortable: false,
        width: 150,
        valueFormatter({ value }) {
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      } as GridColDef<EnforcementIntentSummary, string>,
      {
        field: "licensePlate",
        headerName: "License Plate",
        align: "left",
        headerAlign: "left",
        width: 150,
        valueGetter({ row }) {
          return `${row.licensePlateRegion.split("-")[1].toUpperCase()} ${row.licensePlateNumber}`;
        },
        sortable: false,
      } as GridColDef<EnforcementIntentSummary, string>,
      {
        field: "issuedAt",
        headerName: "Issue Date",
        type: "date",
        width: 159,
        valueGetter({ row }) {
          return new Date(row.issuedAt);
        },
        sortingOrder: ["desc", "asc"],
        filterable: false,
      } as GridColDef<EnforcementIntentSummary, Date>,
      {
        field: "totalAmountDue",
        headerName: "Amount",
        align: "left",
        headerAlign: "left",
        type: "number",
        width: 100,
        valueFormatter({ value }) {
          return `$${formatCurrency(value)}`;
        },
        sortable: false,
      } as GridColDef<EnforcementIntentSummary, number, string>,
      {
        field: "actions",
        type: "actions",
        sortable: false,
        flex: 1,
        getActions: (params) =>
          variant === "pending"
            ? [
                <Button
                  onClick={() => navigate(`${params.row.id}`, { state: { from: "notice-summary" } })}
                  variant="text"
                  color="primary"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Review
                </Button>,
              ]
            : [
                <GridActionsCellItem
                  label={"View Citation"}
                  icon={<ArrowForwardIosIcon sx={{ color: (theme: Theme) => theme.palette.action.active }} />}
                  onClick={() => navigate(`${params.row.id}`, { state: { from: "notice-summary" } })}
                />,
              ],
      } as GridActionsColDef,
    ],
    [variant, navigate],
  );

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    sort: "issuedAt+asc",
  });

  const page = Number(searchParams.get("page") || 1);
  const handlePageChange = (newPage: number) => {
    if (!newPage) {
      return;
    }
    searchParams.set("page", `${newPage}`);
    setSearchParams(searchParams, { replace: true });
  };

  const pageSize = 100;

  const sort = searchParams.get("sort") || "issuedAt+asc";
  const sortModel: GridSortModel = React.useMemo(() => parseSortModel(sort), [sort]);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    searchParams.set("sort", serializeSortModel(newSortModel));
    searchParams.set("page", "1");
    setSearchParams(searchParams, { replace: true });
  };
  const orderBy: EnforcementIntentsOrderBy[] = React.useMemo(() => {
    const { field: sortField, sort: sortDirection } = sortModel[0];
    switch (sortField) {
      case "issuedAt":
        return [{ issued_at: sortDirection || "asc" }];
      default:
        return [{ issued_at: sortDirection || "asc" }];
    }
  }, [sortModel]);

  const getDefaultFilterByVariant = (variant: "pending" | "issued" | "voided"): GetEnforcementIntentsWhereFilter => {
    return variant === "pending"
      ? {
          status: { _eq: "requires_officer_review" },
          reviews: { type: { _eq: "officer" }, reviewed_at: { _is_null: true } },
        }
      : variant === "issued"
      ? { ro_lookup_submitted_at: { _is_null: false } }
      : { voided_at: { _is_null: false }, voided_reason: { _neq: "internal_review" } };
  };

  const [filter, setFilter] = React.useState<GetEnforcementIntentsWhereFilter>(getDefaultFilterByVariant(variant));
  React.useEffect(() => {
    setFilter(getDefaultFilterByVariant(variant));
  }, [variant]);

  const { data, loading, error } = useQuery<GetEnforcementIntentsSummaryData, GetEnforcementIntentsSummaryVars>(
    GET_ENFORCEMENT_INTENTS_SUMMARY,
    {
      variables: {
        offset: (page - 1) * pageSize,
        orderBy,
        filter,
      },
      fetchPolicy: "network-only",
    },
  );

  return (
    <Paper sx={{ borderRadius: 3, maxWidth: 1084, height: 876 }}>
      <DataGridPro
        columns={TABLE_COLUMNS}
        rows={data?.enforcementIntents || []}
        rowCount={data?.enforcementIntentAggregate.aggregate.count || 0}
        loading={loading}
        disableMultipleColumnsSorting
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        error={error}
        pagination
        page={page - 1}
        onPageChange={handlePageChange}
        pageSize={pageSize}
        paginationMode={"server"}
        rowsPerPageOptions={[100]}
        sortingMode={"server"}
        sortModel={sortModel}
        filterMode={"server"}
        // onFilterModelChange={handleFilterChange}
        initialState={{
          sorting: {
            sortModel: [{ field: "issuedAt", sort: "asc" }],
          },
        }}
        onSortModelChange={handleSortModelChange}
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: 0.17,
          },
        }}
      />
    </Paper>
  );
};

export interface NoticeSummaryTableProps {
  variant: "pending" | "issued" | "voided";
}

export default NoticeSummaryTable;
