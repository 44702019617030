import { EnforcementIntentDetail } from "common/graphql";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import NoticeReviewActions from "./NoticeReviewActions";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router-dom";

export const NoticeActionsHeader: React.FC<NoticeActionsHeaderProps> = ({ intent, loading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as { from?: string })?.from;

  const handleClickBack = () => {
    const backButtonNav = from === "notice-summary" ? -1 : "..";
    if (backButtonNav === -1) {
      navigate(-1);
    } else {
      navigate(backButtonNav);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <IconButton onClick={handleClickBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <LoadingSkeleton loading={loading}>
          <Typography variant="h5">Notice Number: {intent?.noticeNumber || ""}</Typography>
        </LoadingSkeleton>
      </Box>
      <Box>{intent?.status === "requires_officer_review" && <NoticeReviewActions id={intent?.id || ""} />}</Box>
    </Box>
  );
};

export interface NoticeActionsHeaderProps {
  intent?: EnforcementIntentDetail;
  loading: boolean;
}

export default NoticeActionsHeader;
