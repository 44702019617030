import React from "react";
import {
  PAYEE_OVERVIEW_TABS_VALUES_CITATIONS,
  PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS,
  PAYEE_OVERVIEW_TABS_VALUES_INVOICES,
  PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS,
  PayeeOverviewTabValue,
} from "../PayeeOverviewTabs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LoadingSkeleton from "../../LoadingSkeleton";
import { formatCurrency } from "common/format";
import { Theme } from "@mui/material/styles";

export const PayeeOverviewContent: React.FC<PayeeOverviewContentProps> = ({
  loading = false,
  payeeOverviewType = PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS,
  overviewCycleTitle = "Monthly",
  overviewCycleDescription = "Running Balance",
  amount = 0,
  currencySymbol = "$",
  percentageChangeSinceLastCycle = 0,
  percentageChangeSinceLastCycleDescription = "Than last month today",
  displayPercentageChange,
}) => {
  return (
    <Box sx={{ width: "100%", fontColor: "palette.text.primary" }}>
      <LoadingSkeleton loading={loading}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS
            ? "Digital Wallet Transactions Overview"
            : payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS
            ? "Park Transactions Overview"
            : payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_INVOICES
            ? "Automated Invoices Overview"
            : "Citation Transactions Overview"}
        </Typography>
      </LoadingSkeleton>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <LoadingSkeleton loading={loading}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              fontWeight={
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_INVOICES ||
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_CITATIONS
                  ? 400
                  : 300
              }
            >
              {overviewCycleTitle}
            </Typography>
            <Typography
              variant="body4"
              fontSize={
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_INVOICES ||
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_CITATIONS
                  ? 12
                  : 14
              }
              letterSpacing={
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_INVOICES ||
                payeeOverviewType === PAYEE_OVERVIEW_TABS_VALUES_CITATIONS
                  ? 0.4
                  : "normal"
              }
            >
              {overviewCycleDescription}
            </Typography>
          </Box>
        </LoadingSkeleton>
        <LoadingSkeleton loading={loading}>
          {displayPercentageChange ? (
            <Stack
              direction={"row"}
              spacing={2.5}
              divider={
                <Divider
                  orientation={"vertical"}
                  flexItem
                  sx={{ border: 1, borderColor: (theme: Theme) => theme.palette.text.disabled }}
                />
              }
            >
              <Typography variant="h3">
                {currencySymbol}
                {formatCurrency(amount)}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {percentageChangeSinceLastCycle >= 0 ? (
                    <ArrowUpwardIcon fontSize="large" color="success" />
                  ) : (
                    <ArrowDownwardIcon fontSize="large" color="error" />
                  )}
                  <Typography
                    variant="h5"
                    color={(theme: Theme) =>
                      percentageChangeSinceLastCycle >= 0 ? theme.palette.success.main : theme.palette.error.main
                    }
                  >
                    {percentageChangeSinceLastCycle >= 0
                      ? percentageChangeSinceLastCycle
                      : -percentageChangeSinceLastCycle}
                    %
                  </Typography>
                </Box>
                <Typography variant="body4" color={(theme: Theme) => theme.palette.text.secondary}>
                  {percentageChangeSinceLastCycleDescription}
                </Typography>
              </Box>
            </Stack>
          ) : (
            <Typography variant="h3">
              {currencySymbol}
              {formatCurrency(amount)}
            </Typography>
          )}
        </LoadingSkeleton>
      </Box>
    </Box>
  );
};

export interface PayeeOverviewContentProps {
  loading?: boolean; // loading state
  payeeOverviewType?: PayeeOverviewTabValue; // type of overview
  overviewCycleTitle?: string; // title of overview cycle
  overviewCycleDescription?: string; // description of overview cycle
  amount?: number; // amount of overview cycle
  currencySymbol?: string; // amount currency symbol of overview cycle
  percentageChangeSinceLastCycle?: number; // percentage change since last overview cycle
  percentageChangeSinceLastCycleDescription?: string; // description of percentage change since last overview cycle
  displayPercentageChange: boolean;
}

export default PayeeOverviewContent;
