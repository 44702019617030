import React from "react";
import Box from "@mui/material/Box";
import EnforcementOverviewLayout from "./EnforcementOverviewLayout";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";
import {
  GetVoidedEnforcementIntentsOverviewData,
  GetVoidedEnforcementIntentsOverviewVars,
  GET_VOIDED_ENFORCEMENT_INTENTS_OVERVIEW,
} from "common/graphql/queries";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import type { Theme } from "@mui/material/styles";

export const VoidedNoticeOverview: React.FC = () => {
  const [now] = React.useState(new Date());

  const startOfToday = startOfDay(now);
  const daysAgo365 = subDays(startOfToday, 365);
  const daysAgo30 = subDays(startOfToday, 30);
  const daysAgo7 = subDays(startOfToday, 7);

  const { data, loading, error, refetch } = useQuery<
    GetVoidedEnforcementIntentsOverviewData,
    GetVoidedEnforcementIntentsOverviewVars
  >(GET_VOIDED_ENFORCEMENT_INTENTS_OVERVIEW, {
    variables: {
      daysAgo365: daysAgo365.toISOString(),
      daysAgo30: daysAgo30.toISOString(),
      daysAgo7: daysAgo7.toISOString(),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <LocalLevelError onTryAgain={refetch} />;
  }

  return (
    <EnforcementOverviewLayout title="Voided Notice Overview">
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <ScoreCard title="Past 365 Days" value={data?.past365Days.aggregate.count || 0} loading={loading} />
        <ScoreCard title="Past 30 Days" value={data?.past30Days.aggregate.count || 0} loading={loading} />
        <ScoreCard title="Past 7 Days" value={data?.past7Days.aggregate.count || 0} loading={loading} />
      </Box>
    </EnforcementOverviewLayout>
  );
};

const ScoreCard: React.FC<ScoreCardProps> = ({ title, value, loading }) => (
  <Box
    sx={{
      width: "290px",
      height: "91px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: "rgba(13, 85, 191, 0.08)",
      boxShadow: (theme: Theme) => theme.shadows[2],
    }}
  >
    <Stack spacing={0.5} sx={{ alignItems: "center", justifyContent: "center" }}>
      <Box>
        <LoadingSkeleton loading={loading}>
          <Typography variant="h6">{value}</Typography>
        </LoadingSkeleton>
      </Box>
      <Box>
        <Typography variant="h7">{title}</Typography>
      </Box>
    </Stack>
  </Box>
);

interface ScoreCardProps {
  title: string;
  value: number;
  loading: boolean;
}

export default VoidedNoticeOverview;
