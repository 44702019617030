import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const EnforcementOverviewLayout: React.FC<EnforcementOverviewLayoutProps> = ({ title, children }) => {
  return (
    <Stack direction="column" spacing={5}>
      <Typography variant="h4">{title}</Typography>
      <Box>{children}</Box>
    </Stack>
  );
};

export interface EnforcementOverviewLayoutProps {
  title: string;
}

export default EnforcementOverviewLayout;
