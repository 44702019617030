import React from "react";
import Box from "@mui/material/Box";
import formatDate from "date-fns/format";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import MonthYearSelectMenu from "@app.automotus.io/components/MonthYearSelectMenu/MonthYearSelectMenu";
import { GridToolbarExport } from "@mui/x-data-grid-pro";
import { Theme } from "@mui/material/styles";

/**
 * Exposes interactions to a payee user to filter tabular transaction data.
 * When display mode is "month", allows the user to select the month of data that will be displayed.
 * When display mode is "day", allows the user to view the currently selected day.
 */
export const PayeeTableToolbar: React.FC<PayeeTableToolbarProps> = ({
  selectedDate,
  onChangeSelectedDate = () => undefined,
  maxDate,
  displayMode,
  exportFileName,
}) => {
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(dropdownAnchorEl);

  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setDropdownAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 52,
        px: 1.25,
        bgcolor: (theme: Theme) => theme.palette.background.paper,
      }}
    >
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: exportFileName }}
        excelOptions={{ fileName: exportFileName }}
      />
      {displayMode === "month" ? (
        <>
          <Button
            id={"table-tools-month-select-dropdown-button"}
            aria-controls={dropdownOpen ? "table-tools-month-select-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={dropdownOpen ? "true" : undefined}
            variant={"text"}
            size={"large"}
            endIcon={<ArrowDropDownIcon />}
            sx={{ textTransform: "none" }}
            onClick={handleClickDropdown}
          >
            {formatDate(selectedDate, MONTH_FORMAT_STRING)}
          </Button>
          <MonthYearSelectMenu
            month={getMonth(selectedDate)}
            year={getYear(selectedDate)}
            maxDate={maxDate}
            open={dropdownOpen}
            anchorEl={dropdownAnchorEl}
            onClose={handleCloseDropdown}
            onChange={onChangeSelectedDate}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </>
      ) : (
        <Button variant={"contained"} disabled size={"large"} sx={{ textTransform: "none" }}>
          {formatDate(selectedDate, DAY_FORMAT_STRING)}
        </Button>
      )}
    </Box>
  );
};

const MONTH_FORMAT_STRING = "MMMM";
const DAY_FORMAT_STRING = "MMMM do, yyyy";

/** Props passed to initialize a {@link PayeeTableToolbar} component instance */
export interface PayeeTableToolbarProps {
  /** Currently selected date for display, or the beginning of the month when displayMode is month */
  selectedDate: Date;
  /** Handler fired when selected date is changed */
  onChangeSelectedDate?: (d: Date) => void;
  /** Display mode */
  displayMode: PayeeTableDisplayMode;
  /** maximum allowed date for selection */
  maxDate: Date;
  /** Name of the exported file. If left undefined, will simply use the page title */
  exportFileName?: string;
}

export type PayeeTableDisplayMode = "day" | "month";

export default PayeeTableToolbar;
