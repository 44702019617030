import React from "react";
import VoidedNoticeOverview from "./VoidedNoticeOverview";
import PendingNoticeOverview from "./PendingNoticeOverview";
import IssuedNoticeOverview from "./IssuedNoticeOverview";

export const EnforcementOverview: React.FC<EnforcementOverviewProps> = ({ variant }) => {
  switch (variant) {
    case "voided":
      return <VoidedNoticeOverview />;
    case "pending":
      return <PendingNoticeOverview />;
    case "issued":
      return <IssuedNoticeOverview />;
    default:
      return null;
  }
};

export interface EnforcementOverviewProps {
  variant: "voided" | "pending" | "issued";
}

export default EnforcementOverview;
