import React from "react";
import Box from "@mui/material/Box";
import { RealTimeOccupancySpaceStatusMapMarker } from "@app.automotus.io/components/RTO/RealTimeOccupancySpaceStatusMapMarker";
import type { Theme } from "@mui/material/styles";

/**
 * Renders a marker for a zone without individually demarcated spaces
 * @param hasOccupant
 * @param hasDoublePark
 * @param hasViolation
 * @param isSelected
 * @constructor
 */
export const RealTimeOccupancyZoneMarker: React.FC<RealTimeOccupancyZoneMarkerProps> = ({
  hasOccupant,
  hasDoublePark,
  hasViolation,
  isSelected,
}) => {
  const Container = hasDoublePark ? DoubleParkContainer : React.Fragment;

  if (hasDoublePark && !hasOccupant) {
    // If there are only double-parked occupants in the zone, then only display the red circle indicating double park
    // status.
    return <Container />;
  }

  return (
    <Container>
      <RealTimeOccupancySpaceStatusMapMarker
        status={isSelected ? "selected" : hasViolation ? "violation" : hasOccupant ? "occupied" : "available"}
      />
    </Container>
  );
};

const DoubleParkContainer: React.FC = ({ children }) => {
  return (
    <Box
      sx={(theme: Theme) => ({
        width: theme.spacing(3.25),
        height: theme.spacing(3.25),
        borderRadius: "50%",
        borderWidth: theme.spacing(0.25),
        borderStyle: "solid",
        borderColor: theme.palette.error.main,
        backgroundColor: "transparent",
        boxSizing: "border-box",
        zIndex: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      {children}
    </Box>
  );
};

export interface RealTimeOccupancyZoneMarkerProps {
  hasOccupant: boolean;
  hasDoublePark: boolean;
  hasViolation: boolean;
  isSelected: boolean;
}

export default RealTimeOccupancyZoneMarker;
