import React from "react";
import Box from "@mui/material/Box";
import type { Theme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import EnforcementOverview from "./EnforcementOverview";
import Container from "@mui/material/Container";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import NoticeSummaryTable from "./NoticeSummaryTable";

export const Notices: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    sort: "issuedAt+asc",
    status: "pending",
  });

  const tab = (searchParams.get("status") as "pending" | "voided" | "issued") || "pending";

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (!newValue) {
      return;
    }
    searchParams.set("status", newValue);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Stack direction="column" spacing={10}>
      <Box
        sx={(theme: Theme) => ({
          pt: "60px",
          pb: "60px",
          backgroundColor: theme.palette.background.paper,
        })}
      >
        <Container maxWidth="lg">
          <Stack direction="column" spacing={7.5}>
            <Tabs value={tab} variant="fullWidth" centered onChange={handleChangeTab}>
              <Tab value="issued" label="Issued Notices" />
              <Tab value="pending" label="Pending Notices" />
              <Tab value="voided" label="Voided Notices" />
            </Tabs>
            <EnforcementOverview variant={tab} />
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="lg">
          <Stack direction="column" spacing={3}>
            <Box>
              <Typography variant="h4">Notices</Typography>
              {tab === "pending" && (
                <Typography variant="subtitle1">Notices 'pending' will not be issued to the curb violator.</Typography>
              )}
              {tab === "voided" && (
                <Typography variant="subtitle1">Notices 'Void' are not mailed to curb violator.</Typography>
              )}
            </Box>
            <NoticeSummaryTable variant={tab} />
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};

export default Notices;
