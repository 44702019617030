import React, { useEffect, useMemo } from "react";
import { PayeeOverview } from "@app.automotus.io/components/PayeeOverview";
import {
  PAYEE_OVERVIEW_TABS_LABELS,
  PAYEE_OVERVIEW_TABS_VALUES_CITATIONS,
  PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS,
  PAYEE_OVERVIEW_TABS_VALUES_INVOICES,
  PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS,
  PayeeOverviewTabValue,
} from "@app.automotus.io/components/PayeeOverview/PayeeOverviewTabs";
import formatDate from "date-fns/format";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import {
  GET_CITATIONS,
  GET_INVOICES,
  GET_PAYEE_TRANSACTIONS_OVERVIEW,
  GetCitationsData,
  GetCitationsVars,
  GetInvoicesData,
  GetInvoicesVars,
  GetPayeeTransactionsOverviewData,
  GetPayeeTransactionsOverviewVars,
} from "common/graphql";
import { getOverviewPeriods } from "@app.automotus.io/components/routes/dashboard/utils";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import type { Theme } from "@mui/material/styles";

export const DashboardLayout: React.FC = () => {
  const [now] = React.useState(new Date());
  const { userProfile } = useUserProfile();
  const navigate = useNavigate();

  const { tabs, activeTab, loading: tabsLoading } = usePayeeTabs();

  const handleChangeTab = useMemo(
    () => (newTab: PayeeOverviewTabValue) => {
      if (newTab === "invoices") {
        navigate("/dashboard/invoices");
        return;
      } else if (newTab === "citations") {
        navigate("/dashboard/citations");
        return;
      } else {
        navigate(`/dashboard?tab=${newTab}`);
        return;
      }
    },
    [navigate],
  );

  useEffect(() => {
    // Navigate to a valid tab if the active tab is not valid
    if (tabs && !tabs.some((tab) => tab.value === activeTab)) {
      handleChangeTab(tabs[0].value);
    }
  }, [handleChangeTab, tabs, activeTab]);

  const isDemoUser = !!(userProfile?.email || "").match(/\+demo@automotus.co/);

  const { curPeriodStart, curPeriodEnd, prevPeriodStart, prevPeriodEnd } = getOverviewPeriods(now);
  const {
    data: overviewData,
    loading: overviewLoading,
    error: overviewError,
    refetch: refetchOverview,
  } = useQuery<GetPayeeTransactionsOverviewData, GetPayeeTransactionsOverviewVars>(GET_PAYEE_TRANSACTIONS_OVERVIEW, {
    variables: {
      prevPeriodStartDate: formatDate(prevPeriodStart, "yyyy-MM-dd"),
      prevPeriodEndDate: formatDate(prevPeriodEnd, "yyyy-MM-dd"),
      curPeriodStartDate: formatDate(curPeriodStart, "yyyy-MM-dd"),
      curPeriodEndDate: formatDate(curPeriodEnd, "yyyy-MM-dd"),
    },
  });

  const {
    data: invoicesData,
    loading: invoicesLoading,
    error: invoicesError,
    refetch: refetchInvoices,
  } = useQuery<GetInvoicesData, GetInvoicesVars>(GET_INVOICES, {
    variables: {
      limit: 31,
      offset: 0,
      orderBy: [{ notice_sent_at: "desc" }],
    },
  });

  const {
    data: citationsData,
    loading: citationsLoading,
    error: citationsError,
    refetch: refetchCitations,
  } = useQuery<GetCitationsData, GetCitationsVars>(GET_CITATIONS, {
    variables: {
      limit: 31,
      offset: 0,
      orderBy: [{ notice_sent_at: "desc" }],
    },
  });

  const overviewSumKeyName =
    activeTab === "digital_wallet_transactions"
      ? "walletTransactionsAmount"
      : isDemoUser
      ? "potentialRevenueAmount"
      : "registeredTransactionsAmount";

  return (
    <Box sx={{ bgcolor: (theme: Theme) => theme.palette.grey[100], pt: 11 }}>
      <PayeeOverview
        tab={activeTab}
        tabs={tabs}
        error={Boolean(overviewError || invoicesError || citationsError)}
        onClickTryAgain={() => {
          refetchOverview();
          refetchInvoices();
          refetchCitations();
        }}
        onChangeTab={handleChangeTab}
        loading={overviewLoading || invoicesLoading || citationsLoading || tabsLoading}
        percentageChangeSinceLastCycle={Math.abs(
          Math.round(
            (100 *
              ((overviewData?.curPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0) -
                (overviewData?.prevPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0))) /
              (overviewData?.prevPeriodAggregate.aggregate.sum[overviewSumKeyName] || 1),
          ),
        )}
        amount={
          activeTab === "invoices"
            ? invoicesData?.invoicesAggregate.aggregate.sum.amountPaid || 0
            : activeTab === "citations"
            ? citationsData?.citationsAggregate.aggregate.sum.paidAmount || 0
            : Math.abs(overviewData?.curPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0)
        }
      />
      <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} width={"100%"} sx={{ py: 10, px: 2.5 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          width={1120}
          maxWidth={"100%"}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const usePayeeTabs = () => {
  const { userProfile, loading } = useUserProfile();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // TODO: make this dynamic
  let invoicesEnabled = false;
  const ACCOUNTS_WITH_INVOICES_ENABLED = [
    process.env.REACT_APP_BETHLEHEM_ACCOUNT_ID,
    process.env.REACT_APP_OMAHA_ACCOUNT_ID,
    process.env.REACT_APP_FORT_LEE_ACCOUNT_ID,
  ];
  if (userProfile?.account?.id && ACCOUNTS_WITH_INVOICES_ENABLED.includes(userProfile.account.id)) {
    invoicesEnabled = true;
  }

  // TODO: make this dynamic
  let citationsEnabled = false;
  const ACCOUNTS_WITH_CITATIONS_ENABLED = [
    process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID,
    process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID,
  ];
  if (userProfile?.account?.id && ACCOUNTS_WITH_CITATIONS_ENABLED.includes(userProfile.account.id)) {
    citationsEnabled = true;
  }

  const payeeHasWallet = userProfile && userProfile.account.wallets.length > 0;

  // Digital wallet transactions are only enabled for payees that have their own
  // gateway
  const digitalWalletTransactionsEnabled =
    payeeHasWallet && userProfile?.account.wallets[0].accountId === userProfile?.account.id;

  // TODO: make this dynamic
  let parkTransactionsEnabled = false;
  if (userProfile?.account?.id !== process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID) {
    parkTransactionsEnabled = true;
  }

  const tabs: { value: PayeeOverviewTabValue; label: string }[] = useMemo(
    () => [
      ...(digitalWalletTransactionsEnabled
        ? [
            {
              value: PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS,
              label: PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS],
            } as const,
          ]
        : []),
      ...(parkTransactionsEnabled
        ? [
            {
              value: PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS,
              label: PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS],
            } as const,
          ]
        : []),
      ...(invoicesEnabled
        ? [
            {
              value: PAYEE_OVERVIEW_TABS_VALUES_INVOICES,
              label: PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_INVOICES],
            } as const,
          ]
        : []),
      ...(citationsEnabled
        ? [
            {
              value: PAYEE_OVERVIEW_TABS_VALUES_CITATIONS,
              label: PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_CITATIONS],
            } as const,
          ]
        : []),
    ],
    [digitalWalletTransactionsEnabled, parkTransactionsEnabled, invoicesEnabled, citationsEnabled],
  );

  const isInvoicesTab = location.pathname.includes("/invoices");
  const isCitationsTab = location.pathname.includes("/citations");
  const activeTab = (
    isInvoicesTab ? "invoices" : isCitationsTab ? "citations" : searchParams.get("tab") || "digital_wallet_transactions"
  ) as PayeeOverviewTabValue;

  return { tabs: loading ? undefined : tabs, activeTab, loading };
};
