import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { convertTimeToString } from "common/format/time";
import { alpha } from "@mui/material/styles";
import { formatVehicleType, formatVehicleColor } from "common/format/occupant";
import RealTimeOccupancyImageViewerDialog from "./RealTimeOccupancyImageViewerDialog";
import { Occupant } from "common/graphql/queries";
import type { Theme } from "@mui/material/styles";

export const VehicleInformationBoxRow: React.FC = ({ children }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      py={1}
      sx={(theme: Theme) => ({
        borderBottomColor: alpha(theme.palette.text.primary, 0.23),
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
      })}
      boxSizing={"border-box"}
    >
      {children}
    </Box>
  );
};

export const RealTimeOccupancySideBarSpaceOccupant: React.FC<RealTimeOccupancySideBarSpaceOccupantProps> = ({
  occupant,
}) => {
  const [imageViewerDialogVisible, setImageViewerDialogVisible] = useState(false);
  const handleClickViewVehicle = () => {
    setImageViewerDialogVisible(true);
  };

  const handleCloseImageViewerDialog = () => {
    setImageViewerDialogVisible(false);
  };

  return (
    <>
      <Box
        width={"100%"}
        height={"100%"}
        boxSizing={"border-box"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"space-between"}
        overflow={"auto"}
      >
        <Box width={"100%"} boxSizing={"border-box"}>
          {occupant.session.violation && (
            <>
              {occupant.vehicle.licensePlateNumber && (
                <VehicleInformationBoxRow>
                  <Typography variant="body1">License Plate</Typography>
                  <Typography variant="body1">
                    {occupant.vehicle.licensePlateRegion} {occupant.vehicle.licensePlateNumber}
                  </Typography>
                </VehicleInformationBoxRow>
              )}
              <VehicleInformationBoxRow>
                <Typography variant="body1">Violation Type</Typography>
                <Typography variant="body1" color={occupant.session.violation?.type ? "error.main" : "text.secondary"}>
                  {occupant.session.violation?.type === "exceeded_max_time"
                    ? "Dwell Time"
                    : occupant.session.violation?.type === "double_park"
                    ? "Double Park"
                    : "-"}
                </Typography>
              </VehicleInformationBoxRow>
            </>
          )}
          <VehicleInformationBoxRow>
            <Typography variant="body1">Vehicle Type</Typography>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              {formatVehicleType(occupant.vehicle.type)}
            </Typography>
          </VehicleInformationBoxRow>
          <VehicleInformationBoxRow>
            <Typography variant="body1">Vehicle Color</Typography>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              {formatVehicleColor(occupant.vehicle.color)}
            </Typography>
          </VehicleInformationBoxRow>
          <Box px={2} py={1.5} width={"100%"} display={"flex"} boxSizing={"border-box"}>
            <Box
              width="50%"
              display={"flex"}
              flexDirection={"column"}
              gap={0.5}
              alignItems={"flex-start"}
              sx={(theme: Theme) => ({
                borderRightColor: alpha(theme.palette.text.primary, 0.23),
                borderRightStyle: "solid",
                borderRightWidth: "1px",
              })}
            >
              <Typography variant="overline">PARK START</Typography>
              <Typography variant="body1" sx={{ textTransform: "uppercase" }}>
                {convertTimeToString(new Date(occupant.session.startTime))}
              </Typography>
            </Box>
            {occupant.session.violation && (
              <Box width="50%" display={"flex"} flexDirection={"column"} gap={0.5} alignItems={"flex-end"}>
                <Typography variant="overline">VIOLATION START</Typography>
                <Typography variant="body1" sx={{ textTransform: "uppercase" }}>
                  {occupant.session.violation.startTime
                    ? convertTimeToString(new Date(occupant.session.violation.startTime))
                    : "-"}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Button
          variant="outlined"
          size="large"
          sx={{
            m: 2,
            mt: "auto",
            mb: 1,
            fontSize: "18px",
            fontWeight: 600,
            textTransform: "none",
          }}
          onClick={handleClickViewVehicle}
        >
          View Vehicle
        </Button>
      </Box>
      <RealTimeOccupancyImageViewerDialog
        open={imageViewerDialogVisible}
        onClose={handleCloseImageViewerDialog}
        occupant={occupant}
      />
    </>
  );
};

export interface RealTimeOccupancySideBarSpaceOccupantProps {
  occupant: Occupant;
}

export default RealTimeOccupancySideBarSpaceOccupant;
