import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import RealTimeOccupancySpaceStatusMapMarker from "../RealTimeOccupancySpaceStatusMapMarker";
import { RealTimeOccupancySpaceStatus } from "../RealTimeOccupancySpaceStatusIndicator";
import * as turf from "@turf/turf";
import { CurbSpace, MapBounds, OnlineZone } from "common/graphql/queries";
import RealTimeOccupancyZoneMarker, {
  RealTimeOccupancyZoneMarkerProps,
} from "@app.automotus.io/components/RTO/RealTimeOccupancyZoneMarker";
import type { Theme } from "@mui/material/styles";

export const RealTimeOccupancySpaceStatusTooltip = {
  available: "Available",
  occupied: "Occupancy Detected",
  occluded: "Occlusion Detected",
  violation: "Violation",
  doubleparked: "Double Park Violation",
  zoneSelected: "Selected",
  selected: "Selected",
};

const priorityOfStatus: Record<RealTimeOccupancySpaceStatus, number> = {
  occluded: 1,
  available: 2,
  occupied: 3,
  doubleparked: 4,
  violation: 5,
  zoneSelected: 6,
  selected: 7,
};

const getStatusWithHigherPriority = (statuses: RealTimeOccupancySpaceStatus[]): RealTimeOccupancySpaceStatus => {
  let highestPriorityStatus: RealTimeOccupancySpaceStatus = "available";
  let highestPriority = 0;

  statuses?.forEach((status) => {
    const priority = priorityOfStatus[status];
    if (priority === 7) {
      highestPriorityStatus = status; // return highestPriorityStatus;
      return;
    }
    if (priority > highestPriority) {
      highestPriority = priority;
      highestPriorityStatus = status;
    }
  });

  return highestPriorityStatus;
};

type SelectedMarker = SelectedMarkerZone | SelectedMarkerSpace;

interface SelectedMarkerBase {
  marker: mapboxgl.Marker;
  zoneId: string;
}

interface SelectedMarkerZone extends SelectedMarkerBase {
  type: "zone";
  props: RealTimeOccupancyZoneMarkerProps;
}

interface SelectedMarkerSpace extends SelectedMarkerBase {
  type: "space";
  status: RealTimeOccupancySpaceStatus;
}

function unselectMarker(selectedMarker: SelectedMarker) {
  const currentMarkerEl = selectedMarker.marker.getElement();
  if (selectedMarker.type === "zone") {
    const newProps = { ...selectedMarker.props, isSelected: false };
    ReactDOM.render(<RealTimeOccupancyZoneMarker {...newProps} />, currentMarkerEl);
  } else {
    ReactDOM.render(<RealTimeOccupancySpaceStatusMapMarker status={selectedMarker.status} />, currentMarkerEl);
  }
}

export const RealTimeOccupancyMap: React.FC<RealTimeOccupancyMapProps> = ({
  mapboxApiKey,
  loading,
  zonesData,
  mapStyle = "mapbox://styles/ashtrix/clkuc7ds6000901po6pw98iuj",
  location,
  minZoom = 13,
  maxZoom = 18,
  onMarkerSelect,
  onMapBoundsChange,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const selectedMarkerRef = useRef<SelectedMarker | null>(null);
  const selectedZoneRef = useRef<{
    marker: mapboxgl.Marker;
  } | null>(null);
  const [, setAddedLayerIds] = useState<string[]>([]); // Array to store added layers
  const [, setAddedMarkers] = useState<mapboxgl.Marker[]>([]); // Array to store added markers

  const addSpaceMarkers = useCallback(
    (zone: OnlineZone, index: number) => {
      if (!mapRef.current && zone?.spaces === null) {
        return;
      }
      if (zone?.spaces !== null && mapRef.current) {
        // Check if any occupant in zone has status doubleparked.
        const hasDoubleParkViolation =
          zone?.occupants && zone?.occupants?.some((occupant) => occupant?.status === "doubleparked");

        if (hasDoubleParkViolation && zone.spaces.length < 2) {
          const doubleparkedMarkerElement = document.createElement("div");
          ReactDOM.render(
            <Box
              sx={(theme: Theme) => ({
                width: theme.spacing(3.25),
                height: theme.spacing(3.25),
                borderRadius: "50%",
                borderWidth: theme.spacing(0.25),
                borderStyle: "solid",
                borderColor: theme.palette.error.main,
                backgroundColor: "transparent",
                boxSizing: "border-box",
                pointerEvents: "none",
              })}
            />,
            doubleparkedMarkerElement,
          );

          doubleparkedMarkerElement.id = `doublepark-marker`;

          const doubleparkedMarkerInstance = new mapboxgl.Marker(doubleparkedMarkerElement).setLngLat(
            zone?.location?.coordinates[0] as [number, number],
          );
          if (zone.spaces.length === 0) {
            doubleparkedMarkerElement.style.cursor = "default";
            const popup = new mapboxgl.Popup({ offset: [0, -15], closeButton: false }).setText(
              RealTimeOccupancySpaceStatusTooltip["doubleparked"],
            );

            doubleparkedMarkerInstance.setPopup(popup);
            doubleparkedMarkerElement.addEventListener("click", (event) => {
              event.stopPropagation();
              mapRef?.current?.flyTo({ center: doubleparkedMarkerInstance.getLngLat(), essential: true });

              ReactDOM.render(<RealTimeOccupancySpaceStatusMapMarker status="selected" />, doubleparkedMarkerElement);
              selectedZoneRef.current = { marker: doubleparkedMarkerInstance };
              onMarkerSelect({ zone, spaceIndex: null, occupantId: null });
            });

            doubleparkedMarkerElement.addEventListener("mouseenter", () => doubleparkedMarkerInstance.togglePopup());
            doubleparkedMarkerElement.addEventListener("mouseleave", () => doubleparkedMarkerInstance.togglePopup());
          }
          doubleparkedMarkerInstance.addTo(mapRef.current);
          setAddedMarkers((prevState) => [...prevState, doubleparkedMarkerInstance]);
        }
        const start = turf.point(zone.location.coordinates[0]);
        const end = turf.point(zone.location.coordinates[1]);
        const bearing = turf.bearing(start, end);

        const INITIAL_DISTANCE_ALONG = 0.2;
        const DISTANCE_ALONG_INCREMENT = 4.5;

        // select first coordinate by default for spacePosition
        let spacePosition = turf.destination(start, INITIAL_DISTANCE_ALONG, bearing, { units: "meters" }).geometry
          .coordinates;

        if (zone.spaces && zone.spaces.length > 0) {
          zone?.spaces.forEach((space: CurbSpace, spaceIndex) => {
            const spaceStatus = space?.isOccluded
              ? "occluded"
              : space?.occupant === null
              ? "available"
              : space?.occupant.status;

            if (spaceStatus) {
              const popup = new mapboxgl.Popup({ offset: [0, -15], closeButton: false }).setText(
                RealTimeOccupancySpaceStatusTooltip[spaceStatus],
              );

              // Calculate distance along the line for placing markers
              const distanceAlong = spaceIndex === 0 ? INITIAL_DISTANCE_ALONG : spaceIndex * DISTANCE_ALONG_INCREMENT;
              spacePosition = turf.destination(start, distanceAlong, bearing, { units: "meters" }).geometry.coordinates;

              const markerElement = document.createElement("div");
              ReactDOM.render(<RealTimeOccupancySpaceStatusMapMarker status={spaceStatus} />, markerElement);
              markerElement.id = `marker-${index}`;
              markerElement.style.zIndex = `${priorityOfStatus[spaceStatus]}`;
              markerElement.style.cursor = "default";
              const markerInstance = new mapboxgl.Marker(markerElement)
                .setLngLat(spacePosition as LngLatLike)
                .setPopup(popup)
                .addTo(mapRef.current as mapboxgl.Map);
              setAddedMarkers((prevState) => [...prevState, markerInstance]);

              markerElement.addEventListener("click", (event) => {
                event.stopPropagation();
                mapRef?.current?.flyTo({ center: markerInstance.getLngLat(), essential: true });
                if (selectedMarkerRef.current) {
                  unselectMarker(selectedMarkerRef.current);
                }
                ReactDOM.render(<RealTimeOccupancySpaceStatusMapMarker status="selected" />, markerElement);
                selectedMarkerRef.current = {
                  marker: markerInstance,
                  zoneId: zone.id,
                  type: "space",
                  status: spaceStatus,
                };
                onMarkerSelect({ zone, spaceIndex: space.index, occupantId: null });
              });

              markerElement.addEventListener("mouseenter", () => markerInstance.togglePopup());
              markerElement.addEventListener("mouseleave", () => markerInstance.togglePopup());
            }
          });
        }
        // Define the coordinates for the LineString
        const lineCoordinates = [zone?.location.coordinates[0], spacePosition];

        // Create a LineString feature
        const lineFeature: turf.Feature<turf.LineString> = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: lineCoordinates,
          },
          properties: {},
        };

        if (hasDoubleParkViolation && zone.spaces.length > 1) {
          // Add the LineString feature to the map
          mapRef.current.addSource(`line-${index}`, {
            type: "geojson",
            data: lineFeature,
          });

          // Add a layer to style the LineString
          mapRef.current.addLayer({
            id: `line-${index}`,
            type: "line",
            source: `line-${index}`,
            layout: {
              "line-cap": "round",
              "line-round-limit": 1.05,
              "line-join": "round",
            },
            paint: {
              "line-color": "#ff0000",
              "line-width": 1.5,
              "line-gap-width": 24,
            },
          });
          setAddedLayerIds((prevState) => [...prevState, `line-${index}`]);
        }
      }
    },
    [onMarkerSelect],
  );

  const addZoneMarker = useCallback(
    (zone: OnlineZone) => {
      if (!mapRef.current || !zone.occupants) {
        return;
      }

      const hasOccupant = zone.occupants.some((occupant) => occupant.status !== "doubleparked");
      const hasDoublePark = zone.occupants.some((occupant) => occupant.status === "doubleparked");
      const hasViolation = zone.occupants.some((occupant) => occupant.status === "violation");
      const isSelected = selectedMarkerRef.current?.zoneId === zone.id;

      const markerEl = document.createElement("div");
      ReactDOM.render(
        <RealTimeOccupancyZoneMarker
          hasOccupant={hasOccupant}
          hasDoublePark={hasDoublePark}
          hasViolation={hasViolation}
          isSelected={isSelected}
        />,
        markerEl,
      );
      markerEl.id = `marker-${zone.id}`;
      markerEl.style.zIndex = `${4}`;
      markerEl.style.cursor = "default";

      let tooltipStatus: RealTimeOccupancySpaceStatus = "available";
      if (zone.occupants.length > 0) {
        const statuses = zone?.occupants?.map((occupant) => occupant?.status);
        tooltipStatus = getStatusWithHigherPriority(statuses || []);
      }

      const popup = new mapboxgl.Popup({ offset: [0, -15], closeButton: false }).setText(
        RealTimeOccupancySpaceStatusTooltip[tooltipStatus],
      );

      const markerInstance = new mapboxgl.Marker(markerEl).setLngLat(zone?.location?.coordinates[0]).setPopup(popup);

      markerEl.addEventListener("click", (event) => {
        event.stopPropagation();
        if (mapRef.current) {
          mapRef.current.flyTo({ center: markerInstance.getLngLat(), essential: true });
        }
        if (selectedMarkerRef.current) {
          unselectMarker(selectedMarkerRef.current);
        }
        ReactDOM.render(
          <RealTimeOccupancyZoneMarker
            hasOccupant={hasOccupant}
            hasDoublePark={hasDoublePark}
            hasViolation={hasViolation}
            isSelected={true}
          />,
          markerEl,
        );
        selectedMarkerRef.current = {
          marker: markerInstance,
          zoneId: zone.id,
          type: "zone",
          props: {
            hasOccupant,
            hasDoublePark,
            hasViolation,
            isSelected: false,
          },
        };
        onMarkerSelect({ zone, spaceIndex: null, occupantId: null });
      });

      markerEl.addEventListener("mouseenter", () => markerInstance.togglePopup());
      markerEl.addEventListener("mouseleave", () => markerInstance.togglePopup());

      markerInstance.addTo(mapRef.current);

      if (isSelected) {
        // If the marker is created already in the selected state, we need to update the value of the ref to ensure that
        // the marker is removed properly upon map click
        selectedMarkerRef.current = {
          marker: markerInstance,
          zoneId: zone.id,
          type: "zone",
          props: {
            hasOccupant,
            hasDoublePark,
            hasViolation,
            isSelected: false,
          },
        };
      }

      setAddedMarkers((prevState) => [...prevState, markerInstance]);
    },
    [onMarkerSelect],
  );

  const handleMapClick = useCallback(() => {
    onMarkerSelect(null);
    if (selectedZoneRef.current && mapRef.current) {
      const { marker: currentMarker } = selectedZoneRef.current;
      const currentMarkerElement = currentMarker.getElement();
      selectedZoneRef.current.marker.addTo(mapRef.current);

      ReactDOM.render(
        <Box
          sx={(theme: Theme) => ({
            width: theme.spacing(3.25),
            height: theme.spacing(3.25),
            borderRadius: "50%",
            borderWidth: theme.spacing(0.25),
            borderStyle: "solid",
            borderColor: theme.palette.error.main,
            backgroundColor: "transparent",
            boxSizing: "border-box",
            pointerEvents: "none",
          })}
        />,
        currentMarkerElement,
      );
      selectedZoneRef.current = null;
    }
    if (selectedMarkerRef.current) {
      unselectMarker(selectedMarkerRef.current);
      selectedMarkerRef.current = null;
    }
  }, [onMarkerSelect]);

  const addZonesAndMarkers = useCallback(() => {
    if (mapRef.current) {
      // Add zones and markers
      if (zonesData?.length > 0) {
        zonesData?.forEach((zone, index) => {
          if (!zone) {
            return;
          }
          if (zone?.spaces) {
            addSpaceMarkers(zone, index);
          } else {
            addZoneMarker(zone);
          }
        });
      }
    }
  }, [zonesData, addSpaceMarkers, addZoneMarker]);

  useEffect(() => {
    if (!mapContainerRef.current || !mapboxApiKey) {
      return;
    }

    if (mapContainerRef.current && !mapRef.current) {
      mapboxgl.accessToken = mapboxApiKey;
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapStyle,
        center: [location.longitude || 0, location.latitude || 0],
        zoom: 15,
        attributionControl: false,
      });

      mapRef.current = map;

      // Set the zoom range
      map.setMinZoom(minZoom);
      map.setMaxZoom(maxZoom > minZoom ? maxZoom : minZoom);

      // disable map rotation using right click + drag
      map.dragRotate.disable();
      // Add a listener for the "load" event
      map.once("load", () => {
        map.on("click", handleMapClick);
        // Get the current bounds of the map
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast(); // North-east corner
        const sw = bounds.getSouthWest(); // South-west corner

        // Call the callback function with the bounds information as an object
        onMapBoundsChange({ minLong: sw.lng, maxLong: ne.lng, minLat: sw.lat, maxLat: ne.lat });
      });
      map.on("style.load", () => {
        return;
      });
    }

    if (mapContainerRef.current && mapRef.current) {
      // Remove all added layers from the map
      setAddedLayerIds((prevAddedLayerIds) => {
        prevAddedLayerIds.forEach((layerId) => {
          mapRef.current?.removeLayer(layerId);
          mapRef.current?.removeSource(layerId);
        });
        return [];
      });

      // Remove all added markers from the map
      setAddedMarkers((prevAddedMarkers) => {
        prevAddedMarkers.forEach((marker) => marker.remove());
        return [];
      });

      // Add a listener for the "dragend" event to return map bounds only when map drag stops
      mapRef.current.on("dragend", () => {
        if (mapRef.current) {
          const bounds = mapRef.current.getBounds();
          const ne = bounds.getNorthEast(); // North-east corner
          const sw = bounds.getSouthWest(); // South-west corner

          // Call the callback function with the bounds information as an object
          onMapBoundsChange({ minLong: sw.lng, maxLong: ne.lng, minLat: sw.lat, maxLat: ne.lat });
        }
      });

      if (zonesData) {
        addZonesAndMarkers();
      }
    }
  }, [
    mapboxApiKey,
    zonesData,
    mapStyle,
    location,
    loading,
    minZoom,
    maxZoom,
    onMarkerSelect,
    addZonesAndMarkers,
    handleMapClick,
    onMapBoundsChange,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        ".mapboxgl-popup": {
          zIndex: 15,
        },
        ".mapboxgl-popup-content": {
          padding: "2px 8px 4px 8px !important",
          backgroundColor: "#333333 !important",
          borderColor: "#333333 !important",
          color: "#ffffff !important",
          borderRadius: "4px !important",
        },
        ".mapboxgl-popup-tip": {
          borderTopColor: "#333333 !important",
          borderWidth: "6px",
        },
        ".mapboxgl-ctrl-logo": {
          display: "none !important",
        },
      }}
    >
      {loading ? (
        <Skeleton width="100%" height="100%" sx={{ transform: "none", flex: 1 }} />
      ) : (
        <Box
          ref={mapContainerRef}
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </Box>
  );
};

export default RealTimeOccupancyMap;

/**
 * Props for the RealTimeOccupancyMap component.
 */
export interface RealTimeOccupancyMapProps {
  /** The Mapbox API key used for authentication. */
  mapboxApiKey: string;
  /** Indicates whether the map is currently loading. */
  loading: boolean;
  /** The location of the map center, specified by longitude and latitude. */
  location: {
    longitude: number;
    latitude: number;
  };
  /** Callback function invoked when a marker is selected. */
  onMarkerSelect: (
    selection: { zone: OnlineZone; spaceIndex: number | null; occupantId: string | null } | null,
  ) => void;
  /**
   * Callback function invoked when the map bounds change.
   * @param bounds The new bounds of the map, specified by minimum and maximum longitude and latitude.
   */
  onMapBoundsChange: (bounds: MapBounds) => void;
  /** An array of zone data representing occupancy information. */
  zonesData: OnlineZone[];
  /** The style URL or JSON object describing the map's style. */
  mapStyle?: string;
  /** The minimum zoom level allowed on the map. */
  minZoom?: number;
  /** The maximum zoom level allowed on the map. */
  maxZoom?: number;
}
