import { EnforcementIntentDetail, EventPolicyComplianceDetail } from "common/graphql";
import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";
import { grey } from "@mui/material/colors";
import differenceInMinutes from "date-fns/differenceInMinutes";
import sumBy from "lodash/sumBy";
import flatMap from "lodash/flatMap";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";

export const GrandTotal: React.FC<GrandTotalProps> = ({ intent, loading }) => {
  const session = intent?.sessions[0];

  const validParkDuration = differenceInMinutes(
    new Date(intent?.sessions[0]?.endedAt || 0),
    new Date(intent?.sessions[0]?.startedAt || 0),
  );
  const validParkAmount = sumBy(session?.transaction?.park?.policyRateDetails || [], "price") || 0;

  const allDetails = flatMap(
    session?.transaction?.park?.policyRateDetails || [],
    "details",
  ) as EventPolicyComplianceDetail[];
  const violatedPark = allDetails.find((detail) => detail.rate === null && detail.price === null);

  const violatedStartTime = new Date(
    violatedPark ? violatedPark.rateStartAt.replace("+00:00", "") : session?.transaction?.park?.startTimeLocal || 0,
  );
  const violatedEndTime = new Date(
    violatedPark ? violatedPark.rateEndAt.replace("+00:00", "") : session?.transaction?.park?.endTimeLocal || 0,
  );

  const violatedParkDuration = differenceInMinutes(new Date(violatedEndTime), new Date(violatedStartTime));
  const violatedParkAmount = intent?.totalAmountDue || 0;

  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box px={3} pt={3} pb={1.5} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={loading} height={60}>
          <Typography variant={"h5"} fontWeight={400}>
            Grand Total
          </Typography>
        </LoadingSkeleton>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Valid Park Duration
            </Typography>
            <Typography variant="body1" letterSpacing={0.15}>
              {`${validParkDuration} mins`}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Amount
            </Typography>
            <Typography variant="body1" textAlign={"right"} letterSpacing={0.15}>
              ${formatCurrency(validParkAmount)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Violation Duration
            </Typography>
            <Typography variant="body1" letterSpacing={0.15}>
              {`${violatedParkDuration} mins`}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Penalty
            </Typography>
            <Typography variant="body1" textAlign={"right"} letterSpacing={0.15}>
              ${formatCurrency(violatedParkAmount)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        py={2.5}
        sx={{
          backgroundColor: "rgba(13, 85, 191, 0.08)",
        }}
      >
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Total Park Duration
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              {`${differenceInMinutes(new Date(session?.endedAt || 0), new Date(session?.startedAt || 0))} mins`}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Total Amount
            </Typography>
            <Typography variant="h6" fontWeight={400} textAlign={"right"}>
              ${formatCurrency(validParkAmount + violatedParkAmount)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
    </Paper>
  );
};

export interface GrandTotalProps {
  loading: boolean;
  intent?: EnforcementIntentDetail;
}

export default GrandTotal;
