import React from "react";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import isAfter from "date-fns/isAfter";
import formatDate from "date-fns/format";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { EnforcementIntentDetail } from "common/graphql";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: -40,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
}));

export const NoticeStatusStepper: React.FC<NoticeStatusStepperProps> = ({ intent }) => {
  const noticeDate = new Date(intent.initialLetterSentAt || 0);
  const dueAt = new Date(intent.dueAt || 0);
  const isPaid = intent.payments.length > 0;
  const paidAt = new Date(intent.payments.length ? intent.payments[0].paidAt || 0 : 0);
  // TODO: actually load this
  const isCollectionsEnabled = false;
  // const sentToCollectionsAt = new Date();

  const isOverdue = isPaid ? isAfter(paidAt, dueAt) : isAfter(new Date(), dueAt);

  const steps = [
    {
      label: "Notice Date",
      date: formatDate(noticeDate, "MM/dd/yyyy"),
      checked: true,
      showDate: true,
    },
    {
      label: "Overdue",
      date: formatDate(dueAt, "MM/dd/yyyy"),
      checked: isOverdue,
      showDate: true,
    },
    // TODO: enable this
    // {
    //   label: "Sent to Collections",
    //   date: formatDate(sentToCollectionsAt, "MM/dd/yyyy"),
    //   checked: !!citation?.sentToCollectionsAt,
    //   showDate: !!citation?.sentToCollectionsAt,
    // },
  ];

  return (
    <Paper
      sx={{
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h5" fontWeight={400}>
          Notice Status
        </Typography>
        <Typography variant="h6" color={isPaid ? "#3A833C" : "error.main"} fontSize="1.25rem" fontWeight={400}>
          {isPaid ? `Paid on ${formatDate(paidAt, "MM/dd/yyyy")}` : "Unpaid"}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <Stepper connector={<CustomStepConnector />} sx={{ width: "100%" }}>
          {steps.slice(0, 3 - (isCollectionsEnabled ? 0 : 1)).map((step) => (
            <Step
              key={step.label}
              completed={step.checked}
              sx={{
                ".MuiStepLabel-iconContainer": {
                  paddingRight: 0,
                },
              }}
            >
              <StepLabel
                sx={{ flexDirection: "column", textAlign: "center", ".MuiStepLabel-label": { lineHeight: 1 } }}
              >
                <Typography variant="subtitle2" fontWeight={500} mt={1} lineHeight={1}>
                  {step.label}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ visibility: step.showDate ? "initial" : "hidden" }}
                  letterSpacing={0.4}
                  lineHeight={1}
                >
                  {step.date}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Paper>
  );
};

export interface NoticeStatusStepperProps {
  intent: EnforcementIntentDetail;
}

export default NoticeStatusStepper;
