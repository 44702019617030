import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import {
  GetEnforcementIntentDetailData,
  GetEnforcementIntentDetailVars,
  GET_ENFORCEMENT_INTENT_DETAIL,
} from "common/graphql/queries/GetEnforcementIntentDetail";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TransactionImageCarousel from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionImageCarousel";
import Grid from "@mui/material/Grid";
import SessionDetailsItem from "@app.automotus.io/components/SessionDetailsItem";
import { convertTimeToString } from "common/format";
import formatDate from "date-fns/format";
import { getViolationTypeLabel } from "../../dashboard/citations/utils";
import Container from "@mui/material/Container";
import CitationTransactionRateBreakdown from "@app.automotus.io/components/CitationTransactionRateBreakdown";
import GrandTotal from "./GrandTotal";
import ViolationPenalty from "./ViolationPenalty";
import NoticeStatusHeader from "./NoticeStatusHeader/NoticeStatusHeader";
import NoticeActionsHeader from "./ActionsHeader/ActionsHeader";

export const Notice: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery<GetEnforcementIntentDetailData, GetEnforcementIntentDetailVars>(
    GET_ENFORCEMENT_INTENT_DETAIL,
    {
      variables: { id: id || "" },
    },
  );

  const intent = data?.enforcementIntent;
  const session = intent?.sessions[0];

  return (
    <Container maxWidth="lg">
      <Stack spacing={3}>
        <NoticeActionsHeader intent={intent} loading={loading} />
        <NoticeStatusHeader loading={loading} intent={intent} />
        <Paper sx={{ p: 3 }}>
          <TransactionImageCarousel
            loading={loading}
            height={404}
            parkStartDate={new Date(session?.transaction.sessionStartedAtLocal || 0)}
            parkEndDate={new Date(session?.transaction.sessionEndedAtLocal || 0)}
            licensePlateCaptureDate={new Date(session?.transaction.sessionStartedAtLocal || 0)}
            parkStartImageSrc={session?.startImageUrl || ""}
            parkEndImageSrc={session?.endImageUrl || ""}
            licensePlateCaptureImageSrc={session?.licensePlateImageUrl || ""}
          />
        </Paper>
        <Paper sx={{ p: 3 }}>
          <Stack spacing={1}>
            <Typography variant={"h5"} fontWeight={400}>
              Notice Details
            </Typography>
            <Grid container rowSpacing={2}>
              <SessionDetailsItem
                loading={loading}
                title={"Violation Date"}
                content={formatDate(new Date(session?.violationAt || 0), "M/d/yyyy")}
                skeletonWidth={104}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Violation Time"}
                content={`${convertTimeToString(new Date(session?.violationAt || 0))} - ${convertTimeToString(
                  new Date(session?.endedAt || 0),
                )}`}
                skeletonWidth={166}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Location"}
                content={session?.transaction.addressStreet || ""}
                skeletonWidth={111}
              />
              <SessionDetailsItem
                loading={loading}
                title={"City"}
                content={`${session?.transaction.addressCity}, ${session?.transaction.addressState}`}
                skeletonWidth={112}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Vehicle"}
                content={
                  intent?.licensePlateNumber &&
                  intent?.licensePlateRegion &&
                  `${intent?.licensePlateRegion.split("-")[1].toUpperCase()} ${intent?.licensePlateNumber}`
                }
                skeletonWidth={104}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Vehicle Make"}
                content={intent?.vehicleMake || ""}
                skeletonWidth={166}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Violation Code"}
                content={intent?.code || ""}
                skeletonWidth={112}
              />
              <SessionDetailsItem
                loading={loading}
                title={"Violation Type"}
                content={getViolationTypeLabel(intent?.violationType || "")}
                skeletonWidth={112}
              />
            </Grid>
          </Stack>
        </Paper>
        <CitationTransactionRateBreakdown
          loading={loading}
          policyRateDetails={session?.transaction.park?.policyRateDetails || []}
        />
        <ViolationPenalty intent={intent} loading={loading} />
        {session?.transaction.payerAccountId && <GrandTotal intent={intent} loading={loading} />}
      </Stack>
    </Container>
  );
};

export default Notice;
