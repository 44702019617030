import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { EventPolicyCompliance, EventPolicyComplianceDetail } from "common/graphql";
import format from "date-fns/format";
import flatMap from "lodash/flatMap";
import Paper from "@mui/material/Paper";
import LoadingSkeleton from "../LoadingSkeleton";

export const CitationTransactionRateBreakdown: React.FC<CitationTransactionRateBreakdownProps> = ({
  loading = true,
  policyRateDetails = [],
}) => {
  const allDetails: EventPolicyComplianceDetail[] = flatMap(policyRateDetails, "details").reduce((acc, detail) => {
    if (acc.length > 0) {
      const next = [...acc];
      const lastDetail = next.pop();
      if (lastDetail.rate === detail.rate) {
        next.push({
          ...lastDetail,
          rateEndAt: detail.rateEndAt,
        });
        return next;
      } else {
        return [...next, lastDetail, detail];
      }
    }

    return [...acc, detail];
  }, []);

  return loading ? (
    <Skeleton width="100%">
      <Box width="100%" height="100px" />
    </Skeleton>
  ) : (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box
        px={3}
        pt={3}
        pb={1.5}
        display="flex"
        justifyContent="space-between"
        borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
      >
        <LoadingSkeleton loading={loading} height={60}>
          <Typography variant={"h5"} fontWeight={400}>
            Time and Rate Breakdown
          </Typography>
        </LoadingSkeleton>
      </Box>
      {allDetails.map(({ id, rateStartAt, rateEndAt, rate }) => (
        <Box
          key={id}
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={2.5}
          borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
        >
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant="h7">
                {format(new Date(rateStartAt.replace("+00:00", "")), TIME_FORMAT)} -{" "}
                {format(new Date(rateEndAt.replace("+00:00", "")), TIME_FORMAT)}
              </Typography>
            </LoadingSkeleton>
          </Box>
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant="h7" textAlign={"right"}>
                ${(rate / 100).toFixed(3)} per min
              </Typography>
            </LoadingSkeleton>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

const TIME_FORMAT = "h:mm aaa";

export interface CitationTransactionRateBreakdownProps {
  loading?: boolean;
  policyRateDetails?: EventPolicyCompliance[];
}

export default CitationTransactionRateBreakdown;
