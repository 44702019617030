import Box from "@mui/material/Box";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Circle from "@mui/icons-material/Circle";
import ShareLocation from "@mui/icons-material/ShareLocation";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import ImageLoad from "@app.automotus.io/components/ImageLoad";
import { displayDistanceLocale } from "common/format/distance";
import type { Theme } from "@mui/material/styles";

export const RealTimeOccupancySideBarZoneSummary: React.FC<RealTimeOccupancySideBarZoneSummaryProps> = ({
  loading = false,
  zoneImageSrc,
  zoneAddress,
  zoneLength,
  selectedSpaceIndex,
  onClose,
  onCopyLocation,
}) => {
  return loading ? (
    <Box sx={{ width: "330px" }}>
      <ImageLoad width={"330px"} height={"160px"} imageUrl={""} alt={""} />
      <Box px={2} display={"flex"} gap={(theme: Theme) => theme.spacing(0.5)} flexDirection={"column"}>
        <Skeleton width="65%" height="32px" sx={{ transform: "none" }} />
        <Skeleton width="38%" height="14px" sx={{ transform: "none" }} />
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: "330px" }}>
      <Box sx={{ position: "relative", mb: (theme: Theme) => theme.spacing(1.25) }}>
        <ImageLoad width={"330px"} height={"160px"} imageUrl={zoneImageSrc || ""} alt={zoneAddress || ""} />
        <Box
          sx={(theme: Theme) => ({
            position: "absolute",
            top: theme.spacing(1.5),
            right: theme.spacing(1.5),
            p: theme.spacing(0.5),
            borderRadius: "100%",
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.action.disabledBackground,
            cursor: "pointer",
            width: theme.spacing(4),
            height: theme.spacing(4),
            boxSizing: "border-box",
          })}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
        <Typography
          variant="caption"
          position={"absolute"}
          bottom={(theme: Theme) => theme.spacing(1.5)}
          right={(theme: Theme) => theme.spacing(1.5)}
          color={(theme: Theme) => theme.palette.grey[500]}
          py={(theme: Theme) => theme.spacing(0.25)}
          px={(theme: Theme) => theme.spacing(0.375)}
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.text.disabled,
          }}
        >
          Zone Reference
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          pl: 2,
          pr: 1.5,
          alignItems: "center",
          justifyContent: "space-between",
          gap: (theme: Theme) => theme.spacing(1),
        }}
      >
        <Box width={"calc(100% - 36px)"}>
          <Tooltip
            title={zoneAddress || ""}
            placement="top"
            arrow
            sx={{
              backgroundColor: "#000000",
            }}
          >
            <Typography
              variant="h6"
              fontWeight={400}
              color={"text.primary"}
              sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
            >
              {zoneAddress}
            </Typography>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              gap: (theme: Theme) => theme.spacing(0.75),
              alignItems: "center",
              color: "text.secondary",
            }}
          >
            <Typography variant="caption">Zone length: {displayDistanceLocale(zoneLength || 0)}</Typography>
            {!!selectedSpaceIndex && (
              <>
                <Circle sx={{ width: "3px", height: "3px" }} />
                <Typography variant="caption">Space ID: {selectedSpaceIndex.toString().padStart(2, "0")}</Typography>
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={(theme: Theme) => ({
            p: theme.spacing(0.5),
            borderRadius: "100%",
            color: theme.palette.action.active,
            cursor: "pointer",
            width: theme.spacing(4),
            height: theme.spacing(4),
            boxSizing: "border-box",
          })}
          onClick={onCopyLocation}
        >
          <Tooltip title="Copy Location" placement="left" arrow>
            <ShareLocation />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export interface RealTimeOccupancySideBarZoneSummaryProps {
  // Indicates whether component is in a loading state
  loading: boolean;
  // URL to image of the zone
  zoneImageSrc?: string;
  // Name of the zone
  zoneAddress?: string;
  // Length of the zone, specified in meters
  zoneLength?: number;
  // Index of the selected space, if any
  selectedSpaceIndex?: number | null;
  // Click handler fired when user clicks the close button
  onClose?: () => void;
  // Click handler fired when user clicks the "Copy Location" button
  onCopyLocation?: () => void;
}

export default RealTimeOccupancySideBarZoneSummary;
