import { GridColDef } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";
import { Theme } from "@mui/material/styles";

export const MONTHLY_DATE_COLUMN: GridColDef = {
  field: "date",
  headerName: "Date",
  type: "date",
  flex: 1,
  sortingOrder: ["desc", "asc"],
};

export const MONTHLY_TOTAL_AMOUNT_COLUMN = (withChevron?: boolean): GridColDef => ({
  field: "amount",
  headerName: "Total Amount",
  type: "number",
  flex: 1,
  align: "left",
  headerAlign: "left",
  sortable: false,
  valueFormatter: ({ value }) => formatCurrency(+value),
  renderCell: (params) => (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
      <Typography
        variant={"body1"}
        sx={{ color: (theme: Theme) => (params.value < 0 ? theme.palette.error.main : undefined) }}
      >
        {`${params.value < 0 ? "-" : ""}$${Math.abs(params.value / 100).toFixed(2)}`}
      </Typography>
      {withChevron && <ArrowForwardIosIcon sx={{ color: (theme: Theme) => theme.palette.action.active }} />}
    </Box>
  ),
});

export const DAILY_TRANSACTION_ID_COLUMN: GridColDef = {
  field: "transactionIndex",
  headerName: "Transaction ID",
  type: "string",
  flex: 1,
  valueGetter({ row }) {
    return row.transactionIndex ? row.transactionIndex.toString().padStart(6, "0") : null;
  },
  sortingOrder: ["desc", "asc"],
};

export const DAILY_TRANSACTION_AMOUNT_COLUMN = (withChevron?: boolean): GridColDef => ({
  field: "amount",
  headerName: "Amount",
  type: "number",
  flex: 1,
  align: "left",
  headerAlign: "left",
  sortable: false,
  valueFormatter: ({ value }) => formatCurrency(+value),
  renderCell: (params) => (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
      <Typography
        variant={"body1"}
        sx={{ color: (theme: Theme) => (params.value < 0 ? theme.palette.error.main : undefined) }}
      >
        {`${params.value < 0 ? "-" : ""}$${Math.abs(params.value / 100).toFixed(2)}`}
      </Typography>
      {withChevron && <ArrowForwardIosIcon sx={{ color: (theme: Theme) => theme.palette.action.active }} />}
    </Box>
  ),
});
