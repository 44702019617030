import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { EnforcementIntentDetail } from "common/graphql";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import type { Theme } from "@mui/material/styles";
import { convertTimeToString, formatCurrency } from "common/format";

export const ViolationPenalty: React.FC<ViolationPenaltyProps> = ({ intent, loading }) => {
  const session = intent?.sessions[0];

  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box px={3} pt={3} pb={1.5} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={loading} height={60}>
          <Typography variant={"h5"} fontWeight={400}>
            Violation Penalty
          </Typography>
        </LoadingSkeleton>
        {session?.transaction.payerAccountId && (
          <LoadingSkeleton loading={loading} height={60}>
            <Typography
              variant="h6"
              color={intent?.payments?.length ? "#3A833C" : "error.main"}
              fontSize="1.25rem"
              fontWeight={400}
            >
              {intent?.payments?.length ? "Paid" : "Unpaid"}
            </Typography>
          </LoadingSkeleton>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Violation Time
            </Typography>
            <Typography variant="body1" letterSpacing={0.15}>
              {convertTimeToString(new Date(session?.violationAt || 0))} -{" "}
              {convertTimeToString(new Date(session?.endedAt || 0))}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Amount
            </Typography>
            <Typography variant="body1" textAlign={"right"} letterSpacing={0.15}>
              ${formatCurrency(intent?.totalAmountDue || 0)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      {!session?.transaction.payerAccountId && (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={2.5}
          sx={{
            backgroundColor: "rgba(13, 85, 191, 0.08)",
          }}
        >
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant="h6" fontWeight={400}>
                Total Amount
              </Typography>
            </LoadingSkeleton>
          </Box>
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant="h6" fontWeight={400}>
                ${formatCurrency(intent?.totalAmountDue || 0)}
              </Typography>
            </LoadingSkeleton>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export interface ViolationPenaltyProps {
  intent?: EnforcementIntentDetail;
  loading?: boolean;
}

export default ViolationPenalty;
