import React from "react";
import Box from "@mui/material/Box";
import EnforcementOverviewLayout from "./EnforcementOverviewLayout";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import {
  GetPendingEnforcementIntentsOverviewData,
  GET_PENDING_ENFORCEMENT_INTENTS_OVERVIEW,
} from "common/graphql/queries";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { formatCurrency } from "common/format";

export const PendingNoticeOverview: React.FC = () => {
  const { data, loading, error, refetch } = useQuery<GetPendingEnforcementIntentsOverviewData>(
    GET_PENDING_ENFORCEMENT_INTENTS_OVERVIEW,
    {
      fetchPolicy: "network-only",
    },
  );

  if (error) {
    return <LocalLevelError onTryAgain={refetch} />;
  }

  return (
    <EnforcementOverviewLayout title="Pending Notice Overview">
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography variant="h5">Total Pending Revenue</Typography>
        </Box>
        <Box>
          <LoadingSkeleton loading={loading || !data}>
            <Typography variant="h3">
              ${formatCurrency(data?.enforcementIntentAggregate.aggregate.sum.totalAmountDue || 0, 2)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
    </EnforcementOverviewLayout>
  );
};

export default PendingNoticeOverview;
