import { useMutation } from "@apollo/client";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { REVIEW_ENFORCEMENT_INTENT, ReviewEnforcementIntentData, ReviewEnforcementIntentVars } from "common/graphql";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

export const NoticeReviewActions: React.FC<NoticeReviewActionsProps> = ({ id }) => {
  const [isVoidDialogOpen, setIsVoidDialogOpen] = React.useState(false);
  const [deniedReason, setDeniedReason] = React.useState<string>("");
  const { publishSnackbarMessage } = useSnackPackContext();
  const [isVoiding, setIsVoiding] = React.useState(false);
  const [isIssuing, setIsIssuing] = React.useState(false);
  const navigate = useNavigate();

  const [reviewEnforcementIntent, { loading: isSubmitting }] = useMutation<
    ReviewEnforcementIntentData,
    ReviewEnforcementIntentVars
  >(REVIEW_ENFORCEMENT_INTENT);

  const handleCloseVoidDialog = () => {
    setIsVoidDialogOpen(false);
  };

  // TODO: handle error and success
  const handleVoidEnforcementIntent = () => {
    setIsVoiding(true);
    reviewEnforcementIntent({
      variables: { intentId: id, approve: false, deniedReason: deniedReason },
      onError() {
        setIsVoiding(false);
      },
      onCompleted() {
        setIsVoiding(false);
        publishSnackbarMessage({
          message: "Void successful",
          severity: "error",
        });
        navigate(-1);
      },
    });
  };

  const handleIssueEnforcementIntent = () => {
    setIsIssuing(true);
    reviewEnforcementIntent({
      variables: { intentId: id, approve: true },
      onError() {
        setIsIssuing(false);
      },
      onCompleted() {
        setIsIssuing(false);
        publishSnackbarMessage({
          message: "Issue successful",
          severity: "success",
        });
        navigate(-1);
      },
    });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 2 }}>
      <Button disabled={isSubmitting} variant="contained" color="error" onClick={() => setIsVoidDialogOpen(true)}>
        Void
      </Button>
      <LoadingButton
        disabled={isSubmitting}
        loading={isIssuing}
        variant="contained"
        color="primary"
        onClick={handleIssueEnforcementIntent}
      >
        Issue
      </LoadingButton>
      <Dialog open={isVoidDialogOpen} onClose={handleCloseVoidDialog} sx={{ px: 4 }}>
        <DialogTitle>
          <Typography variant="h4">Voiding a Notice?</Typography>
          <Typography variant="body1">Select from the below options</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="subtitle1">Tell us what is wrong with the notice</Typography>
            <Typography variant="caption">
              Fill out this field if you'd like! It really helps our team at Automotus make any necessary updates to our
              software if there's an issue we can address.
              <br /> <br />
              Thanks for your help!
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="reason-for-void-label">Reason for Void</InputLabel>
              <Select
                disabled={isSubmitting}
                labelId="reason-for-void-label"
                value={deniedReason}
                label="Reason for Void"
                placeholder="Reason for Void"
                onChange={(e) => setDeniedReason(e.target.value)}
              >
                <MenuItem value="wrong license plate number">Wrong License Plate Number</MenuItem>
                <MenuItem value="legal event">Legal Event</MenuItem>
                <MenuItem value="handicap plate">Handicap License Plate</MenuItem>
                <MenuItem value="government vehicle">Government Vehicle</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <Stack sx={{ mt: 6 }} spacing={3}>
              <LoadingButton
                loading={isVoiding}
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="error"
                onClick={handleVoidEnforcementIntent}
              >
                Void Notice
              </LoadingButton>
              <Button disabled={isSubmitting} fullWidth variant="contained" onClick={handleCloseVoidDialog}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export interface NoticeReviewActionsProps {
  id: string;
}

export default NoticeReviewActions;
