import React from "react";
import type { Theme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Outlet } from "react-router-dom";

export const NoticesLayout: React.FC = () => {
  return (
    <Stack
      direction="column"
      spacing={10}
      sx={{
        bgcolor: (theme: Theme) => theme.palette.grey[100],
        py: 3.5,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: "100vh",
      }}
    >
      <Outlet />
    </Stack>
  );
};

export default NoticesLayout;
