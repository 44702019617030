import React from "react";
import { PayeeOverviewTabs, PayeeOverviewTabValue } from "@app.automotus.io/components/PayeeOverview/PayeeOverviewTabs";
import { PayeeOverviewContent } from "@app.automotus.io/components/PayeeOverview/PayeeOverviewContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";

/**
 * Displays an overview of recent transactions to a payee user
 */
export const PayeeOverview: React.FC<PayeeOverviewProps> = ({
  tab,
  tabs,
  onChangeTab,
  loading,
  percentageChangeSinceLastCycle,
  amount,
  error = false,
  onClickTryAgain = () => undefined,
}) => {
  return (
    <Box display={"flex"} justifyContent={"center"} sx={{ bgcolor: "background.paper", pt: 7.5, pb: 12.5, px: 2.5 }}>
      <Box sx={{ width: 1084 }}>
        {error ? (
          <LocalLevelError onTryAgain={onClickTryAgain} />
        ) : (
          <Stack spacing={7.5}>
            <PayeeOverviewTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />
            <PayeeOverviewContent
              loading={loading}
              payeeOverviewType={tab}
              amount={amount}
              percentageChangeSinceLastCycle={percentageChangeSinceLastCycle}
              overviewCycleTitle={tab === "invoices" || tab === "citations" ? "Total Revenue" : undefined}
              overviewCycleDescription={tab === "invoices" || tab === "citations" ? "to date" : undefined}
              displayPercentageChange={tab !== "invoices" && tab !== "citations"}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

/** Props passed to initialize a {@link PayeeOverview} component. */
export interface PayeeOverviewProps {
  /** Currently selected tab */
  tab: PayeeOverviewTabValue;
  /** All available tabs */
  tabs?: { value: PayeeOverviewTabValue; label: string }[];
  /** Change handler fired when tab is changed */
  onChangeTab: (tab: PayeeOverviewTabValue) => void;
  /** Loading status of the component */
  loading?: boolean;
  /** Currency amount to display (expressed as an integer number of the smallest denomination of the payee's currency */
  amount?: number;
  /** Percentage change of the currently displayed period relative to the previous period */
  percentageChangeSinceLastCycle?: number;
  /** Indicates error state of component */
  error?: boolean;
  /** Handler fired when user requests to try again */
  onClickTryAgain?: () => void;
}

export default PayeeOverview;
