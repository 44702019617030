import React from "react";
import { EnforcementIntentDetail } from "common/graphql";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import type { Theme } from "@mui/material/styles";
import NoticeStatusStepper from "./NoticeStatusStepper";

export const NoticeStatusHeader: React.FC<NoticeStatusHeaderProps> = ({ loading, intent }) => {
  if (loading || !intent) {
    return <Skeleton variant="rectangular" width="100%" height="50px" />;
  }

  if (intent.status === "voided") {
    return (
      <Alert
        severity="info"
        sx={(theme: Theme) => ({ color: theme.palette.text.secondary, backgroundColor: theme.palette.grey[300] })}
      >
        This notice has been voided.
      </Alert>
    );
  }

  if (intent.roLookupSubmittedAt && !intent.initialLetterSentAt) {
    return <Alert severity="info">This notice has been submitted for registered owner lookup.</Alert>;
  }

  if (intent.roLookupSubmittedAt && intent.initialLetterSentAt) {
    return <NoticeStatusStepper intent={intent} />;
  }

  return null;
};

export interface NoticeStatusHeaderProps {
  loading?: boolean;
  intent?: EnforcementIntentDetail;
}

export default NoticeStatusHeader;
